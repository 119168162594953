import { FC } from 'react';
import IconProps from './IconProps';
import { dataAttributeProps } from '../../../utils/ComponentUtils';

const TrashIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      {...dataAttributeProps(props)}
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 3L9 3.9H4V5.7H5V19.2C5 19.67 5.19133 20.1491 5.56836 20.4885C5.94539 20.8278 6.47778 21 7 21H17C17.5222 21 18.0546 20.8278 18.4316 20.4885C18.8087 20.1491 19 19.67 19 19.2V5.7H20V3.9H15L14 3H10ZM7 5.7H17V19.2H7V5.7ZM9 7.5V17.4H11V7.5H9ZM13 7.5V17.4H15V7.5H13Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TrashIcon;
